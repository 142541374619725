import React, { useEffect, useRef, useState } from "react";
import { Flex, HStack, Link, StackProps } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import styled from "styled-components";

interface Props {
  pagina_ascendentes: {
    id: number;
    titulo: string;
    slug: string;
  };
  menuItems: {
    id: number;
    titulo: string;
    slug: string;
  }[];
}

const MotionHStack = motion<StackProps>(HStack);

const FlexStyle = styled(Flex)`
  a.active {
    background-color: #00377b;
  }
`;

export default function MenuInternoPagina(props: Props) {
  const router = useRouter();
  const { slug } = router.query;
  const stackRef = useRef<HTMLDivElement | null>(null);
  const [constraintsRef, setConstraintsRef] = useState<Object>({});
  useEffect(() => {
    // get element width
    const scrollWidth = stackRef.current ? stackRef.current.scrollWidth : 0;
    const offsetWidth = stackRef.current ? stackRef.current.offsetWidth : 0;
    const constraints = {
      left: -(scrollWidth - offsetWidth + 50),
      right: 0,
    };
    setConstraintsRef(constraints);
  }, []);
  return (
    <FlexStyle w="full" flexDir="column">
      <Flex
        w={["100%", "100%", "100%", "100%", "1170px"]}
        mx="auto"
        py={10}
        flexDir="column"
        pl={[10, 10, 10, 10, 0]}
      >
        <MotionHStack
          spacing={3}
          drag="x"
          dragConstraints={constraintsRef}
          ref={stackRef}
        >
          {props.menuItems &&
            props.menuItems.map((menu) => (
              <NextLink key={menu.id} href={`/${menu.slug}`} passHref>
                <Link
                  className={String(slug) === menu.slug ? "active" : ""}
                  p="10px 20px"
                  bgColor="#007DC5"
                  borderRadius="12px"
                  color="white"
                  whiteSpace="nowrap"
                  fontSize="14px"
                  fontWeight="bold"
                  _hover={{
                    bgColor: "#00377B",
                  }}
                >
                  {menu.titulo}
                </Link>
              </NextLink>
            ))}
        </MotionHStack>
      </Flex>
    </FlexStyle>
  );
}
