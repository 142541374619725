import React from "react";
import { Flex, Link, Stack } from "@chakra-ui/react";
import NextLink from "next/link";

interface Props {
  linksRedirecionamento: {
    id: number;
    rotulo: string;
    link: string;
    ativarLinkExterno: boolean;
    arquivo: {
      id: number;
      name: string;
      url: string;
    };
  }[];
}

export default function Links(props: Props) {
  return (
    <Flex w="full" flexDir="column">
      <Flex w={["80%", "80%", "90%", "90%", "1170px"]} mx="auto">
        <Stack>
          {props.linksRedirecionamento &&
            props.linksRedirecionamento.map((item) => (
              <NextLink
                key={item.id}
                href={
                  item.ativarLinkExterno
                    ? String(item.link)
                    : String(item.arquivo?.url)
                }
                passHref
              >
                <Link
                  p="10px 20px"
                  bgColor="#007DC5"
                  borderRadius="12px"
                  color="white"
                  fontSize="14px"
                  fontWeight="bold"
                  target="_blank"
                  _hover={{
                    bgColor: "#00377B",
                  }}
                >
                  {item.rotulo}
                </Link>
              </NextLink>
            ))}
        </Stack>
      </Flex>
    </Flex>
  );
}
