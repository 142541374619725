import React from "react";
import {
  Box,
  Flex,
  HStack,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react";
import RichText from "../../RichText";

interface Props {
  id: number;
  titulo: string;
  texto: string;
  itemLista: ItemListProps[];
}

interface ItemListProps {
  id: number;
  titulo: string;
  texto: string;
}

export default function ListaOrdenada(props: Props) {
  return (
    <Flex w="full" flexDir="column">
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        py={10}
        flexDir="column"
      >
        {props.itemLista && (
          <OrderedList listStyleType="none" m={0}>
            {props.itemLista.map((item, index) => (
              <ListItem key={item.id}>
                <Text w="full" fontSize="24px" mb={3} mt={8}>
                  <Box
                    as="span"
                    bgImage="/bg-lista-ordenada.png"
                    bgRepeat="no-repeat"
                    bgSize="contain"
                    w="41px"
                    h="42px"
                    display="inline-block"
                    textAlign="center"
                    p="10px"
                    fontSize="18px"
                    color="white"
                    mr={4}
                  >
                    {index + 1}
                  </Box>
                  {item.titulo}
                </Text>
                <RichText text={item.texto} />
              </ListItem>
            ))}
          </OrderedList>
        )}
      </Flex>
    </Flex>
  );
}
