import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form as Unform } from "@unform/web";
import Input from "./Input";
import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import styled from "styled-components";
import { useToast } from "@chakra-ui/react";
import { IMedia } from "../../../../interfaces/Media";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

interface ErrorProps {
  [key: string]: string;
}
interface FormData {
  name: string;
  empresa: string;
  email: string;
}

interface DonwloadCategoryProps {
  id: number;
  titulo: string;
  slug: string;
}

interface DonwloadProps {
  id: number;
  titulo: string;
  link: string;
  solicitarCadastro: boolean;
  imagem: IMedia;
  download_categorias: DonwloadCategoryProps[];
  ativarLinkExterno: boolean;
  arquivo: {
    id: number;
    name: string;
    url: string;
  };
}

interface Props {
  download: DonwloadProps | undefined;
}

export const Form = styled(Unform)`
  width: 100%;
`;

export default function FormDownloadModal({ download }: Props) {
  const formRef = useRef<FormHandles>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const toast = useToast();

  const recaptchaRef = React.createRef<any>();

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      // Remove all previous errors
      if (formRef.current != null) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        nome: Yup.string()
          .max(60, "Máximo de 60 caracteres.")
          .required("Campo obrigatório."),
        empresa: Yup.string()
          .max(60, "Máximo de 60 caracteres.")
          .required("Campo obrigatório."),
        email: Yup.string()
          .email("Digite um e-mail válido.")
          .required("Campo obrigatório."),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const recaptchaValue = recaptchaRef.current?.getValue();
      if (!recaptchaValue) {
        toast({
          title: "Erro",
          description: "Por favor, confirme que você não é um robô.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Validation passed

      // SEND MAIL
      const formInfo = data;
      {
        let data = new FormData();
        data.append("data", JSON.stringify(formInfo));

        const sending = await axios.post(
          `${
            process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
          }/form/download`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      console.log(data);
      // open link to anoter page
      if (download?.ativarLinkExterno) {
        window.open(download?.link, "_blank");
      } else {
        window.open(download?.arquivo.url, "_blank");
      }

      toast({
        title: "Solicitação enviada.",
        description:
          "Em breve você recebera em seu e-mail o link para download.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (err) {
      const validationErrors: ErrorProps = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.path != undefined) {
            validationErrors[error.path] = error.message;
          }
        });
        if (formRef.current != null) {
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  };
  return (
    <Flex w="full">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid templateColumns="1fr" gap="4">
          <GridItem>
            <Input
              name="nome"
              label="Nome completo"
              placeholder="Insira seu nome completo"
            />
          </GridItem>
          <GridItem>
            <Input
              name="empresa"
              label="Empresa"
              placeholder="Insira o nome da empresa"
            />
          </GridItem>
          <GridItem>
            <Input
              name="email"
              label="E-mail"
              placeholder="Insira seu e-mail"
            />
          </GridItem>
        </Grid>

        <Flex justifyContent="space-between">
          <Button
            mt={4}
            colorScheme="teal"
            isLoading={isSubmit}
            type="submit"
            bgColor="#007DC5"
            fontSize="14px"
            borderRadius="12px"
            px={14}
            _hover={{ bgColor: "#007DC5" }}
          >
            Download do material
          </Button>
          <ReCAPTCHA
            sitekey="6LfAbYUeAAAAANymlm8n_qR1FqN9KFEWtG1Bd0yN"
            ref={recaptchaRef}
          />
        </Flex>
      </Form>
    </Flex>
  );
}
