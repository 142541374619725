import React from "react";
import { Flex } from "@chakra-ui/react";
import RichText from "../../RichText";

interface Props {
  textoPrimeiro: string;
  textoSegundo: string;
}

export default function DuasColunasTexto(props: Props) {
  return (
    <Flex w="full" flexDir="column">
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        flexDir={["column", "column", "column", "row"]}
        mx="auto"
        py={[4, 4, 4, 10]}
      >
        <Flex flexDir="column" w="full" maxW={["100%", "100%", "100%", "50%"]}>
          {props.textoPrimeiro && <RichText text={props.textoPrimeiro} />}
        </Flex>
        <Flex flexDir="column" w="full" maxW={["100%", "100%", "100%", "50%"]}>
          {props.textoSegundo && <RichText text={props.textoSegundo} />}
        </Flex>
      </Flex>
    </Flex>
  );
}
