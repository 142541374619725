import { Flex, FlexProps } from "@chakra-ui/react";
import { motion } from "framer-motion";
import BannerPrincipal from "../../blocks/BannerPrincipal";
import Hero from "../../blocks/Hero";
import CarrosselCategorias from "../../blocks/CarrosselCategorias";
import Video from "../../blocks/Video";
import BoxTextoImagem from "../../blocks/BoxTextoImagem";
import MenuInternoPagina from "../../blocks/MenuInternoPagina";
import DuasColunasComImagem from "../../blocks/DuasColunasComImagem";
import DuasColunasTexto from "../../blocks/DuasColunasTexto";
import BoxDestaqueVariasColunas from "../../blocks/BoxDestaqueVariasColunas";
import UmaColunaTexto from "../../blocks/UmaColunaTexto";
import Links from "../../blocks/Links";
import GerarBoletos from "../../blocks/GerarBoleto";
import DownloadArea from "../../blocks/DownloadArea";
import Imagem from "../../blocks/Imagem/Imagem";
import ListaOrdenada from "../../blocks/ListaOrdenada";
import FormularioRepresentantes from "../../blocks/FormularioRepresentantes";
import Representantes from "../../blocks/Representantes";

const MotionFlex = motion<FlexProps>(Flex);

const getBlockComponent = ({ __component, ...rest }: any, index: string) => {
  const boxVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // const controls = useAnimation();
  // const { ref, inView } = useInView();

  // useEffect(() => {
  //   if (inView) {
  //     controls.start("visible");
  //   }
  //   if (!inView) {
  //     controls.start("hidden");
  //   }
  // }, [controls, inView]);

  let Block;

  switch (__component) {
    case "blocks.hero":
      Block = Hero;
      break;
    case "blocks.banner-principal":
      Block = BannerPrincipal;
      break;
    case "blocks.carrossel-categorias":
      Block = CarrosselCategorias;
      break;
    case "blocks.video":
      Block = Video;
      break;
    case "blocks.box-texto-imagem":
      Block = BoxTextoImagem;
      break;
    case "blocks.menu-interno-pagina":
      Block = MenuInternoPagina;
      break;
    case "blocks.duas-colunas-com-imagem":
      Block = DuasColunasComImagem;
      break;
    case "blocks.duas-colunas-texto":
      Block = DuasColunasTexto;
      break;
    case "blocks.box-destaque-varias-colunas":
      Block = BoxDestaqueVariasColunas;
      break;
    case "blocks.uma-coluna-texto":
      Block = UmaColunaTexto;
      break;
    case "blocks.links":
      Block = Links;
      break;
    case "blocks.gerar-boleto":
      Block = GerarBoletos;
      break;
    case "blocks.area-downloads":
      Block = DownloadArea;
      break;
    case "blocks.imagem":
      Block = Imagem;
      break;
    case "blocks.listagem-ordenada":
      Block = ListaOrdenada;
      break;
    case "blocks.formulario-representante":
      Block = FormularioRepresentantes;
      break;
    case "blocks.representantes":
      Block = Representantes;
      break;
  }

  return Block ? (
    <MotionFlex
      key={`index-${index}`}
      // ref={ref}
      as="section"
      w="full"
      initial="hidden"
      animate="visible"
      // animate={controls}
      variants={boxVariants}
    >
      <Block {...rest} />
    </MotionFlex>
  ) : null;
};

interface BlockManagerProps {
  blocks: any;
}

const BlockManager = ({ blocks }: BlockManagerProps) => {
  return blocks.map(getBlockComponent);
};

BlockManager.defaultProps = {
  blocks: [],
};

export default BlockManager;
