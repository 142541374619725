import React, { useEffect, useRef, useState } from "react";
import { Box, BoxProps, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import ReactPlayer from "react-player";
import { IMedia } from "../../../interfaces/Media";
import { getMedia } from "../../../lib/media";
import IconPlay from "../../../assets/icons/icon-play.svg";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

interface Props {
  id: number;
  subtitulo: string;
  titulo: string;
  linkVideoDesktop: string;
  linkVideoCelular: string;
  capaDesktop: IMedia;
  capaCelular: IMedia;
}
const MotionBox = motion<BoxProps>(Box);

export default function Video(props: Props) {
  const [play, setPlay] = useState<boolean>(false);
  const videoRef = useRef<any>(null);

  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });
  const [videoHeight, setVideoHeight] = useState<string>("560px");
  useEffect(() => {
    if (isSmallerThan768) {
      setVideoHeight("250px");
    } else {
      setVideoHeight("560px");
    }
  }, [isSmallerThan768]);

  return (
    <Flex w="full" flexDir="column">
      <Flex w="full" maxW={["1170px"]} mx="auto" py={10} flexDir="column">
        {props.titulo && (
          <Flex
            flexDir="column"
            flex="1"
            maxW={["80%", "80%", "90%", "90%", "1170px"]}
            w="full"
            mx="auto"
          >
            {props.subtitulo && (
              <Text
                color="#017DC5"
                fontSize="14px"
                textTransform="uppercase"
                mb={4}
              >
                {props.subtitulo}
              </Text>
            )}
            <Heading as="h2" fontSize="24px" color="#101010" pr="40%">
              {props.titulo}
            </Heading>
            <Divider
              bgColor="#ED1B2F"
              height={1}
              borderRadius={4}
              maxW="40px"
              mt={4}
              mb={8}
            />
          </Flex>
        )}
        <Flex flexDir="column" position="relative">
          <Flex>
            <ReactPlayer
              ref={videoRef}
              width="100%"
              height={videoHeight}
              playing
              playIcon={
                <MotionBox initial={{ scale: 1 }} whileHover={{ scale: 1.2 }}>
                  <IconPlay />
                </MotionBox>
              }
              url={props.linkVideoDesktop}
              light={getMedia(props.capaDesktop)}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
