import React, { useEffect, useState } from "react";
import { Flex, Image } from "@chakra-ui/react";
import TitleDefault from "../../TitleDefault";
import { IMedia } from "../../../interfaces/Media";
import { getMedia } from "../../../lib/media";
import RichText from "../../RichText";
import { useMediaQuery } from "react-responsive";

interface Props {
  id: number;
  subtitulo: string;
  titulo: string;
  texto: string;
  reverterColuna: boolean;
  imagem: IMedia;
}

export default function DuasColunasComImagem(props: Props) {
  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });
  const [flexDir, setFlexDir] = useState<React.CSSProperties["flexDirection"]>(
    props.reverterColuna ? "row-reverse" : "row"
  );

  useEffect(() => {
    if (isSmallerThan768) {
      setFlexDir("column");
    } else {
      setFlexDir(props.reverterColuna ? "row-reverse" : "row");
    }
  }, [isSmallerThan768]);

  return (
    <Flex w="full" flexDir="column">
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        py={10}
        flexDir="column"
      >
        <Flex
          flex="1"
          justifyContent="space-between"
          position="relative"
          flexDir={flexDir}
        >
          <Flex
            w="full"
            flexDir="column"
            justifyContent="flex-start"
            pr={[0, 0, 10]}
          >
            <TitleDefault subtitulo={props.subtitulo} titulo={props.titulo} />
            {props.texto && <RichText text={props.texto} />}
          </Flex>
          <Flex w="full" maxW={["100%", "100%", "100%", "45%"]}>
            {props.imagem && (
              <Image
                src={getMedia(props.imagem)}
                w="100%"
                position="relative"
                zIndex="1"
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
