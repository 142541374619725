import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React, { useEffect, useRef, useState } from "react";

import { IMedia } from "../../../interfaces/Media";
import NextLink from "next/link";
import Slider from "react-slick";
import { chunk } from "lodash";
import { getMedia } from "../../../lib/media";
import { useMediaQuery } from "react-responsive";

interface ICategoria {
  id: number;
  categoria: {
    id: number;
    nome: string;
    slug: string;
    descricao: string;
    imagem: IMedia;
    imagemSecundaria: IMedia;
  };
  nome: string;
}
interface Props {
  id?: number;
  subtitulo: string;
  titulo: string;
  mostrarTodos?: boolean;
  linhaDupla?: boolean;
  categorias: ICategoria[];
}

export default function CarrosselCategorias(props: Props) {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
  };

  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });
  const [templateColumns, setTemplateColumns] = useState("repeat(1, 1fr)");
  const [itemPerSlide, setItemPerSlide] = useState(props.linhaDupla ? 2 : 1);
  const [slidetoShow, setSlidetoShow] = useState(2);

  useEffect(() => {
    if (isSmallerThan768) {
      setItemPerSlide(props.linhaDupla ? 2 : 1);
      setTemplateColumns("repeat(1, 1fr)");
      setSlidetoShow(2);
    } else {
      setItemPerSlide(props.linhaDupla ? 2 : 1);
      setTemplateColumns("repeat(1, 1fr)");
      setSlidetoShow(4);
    }
  }, [isSmallerThan768]);

  // console.log(props.categorias);
  useEffect(() => {
    console.log("props categorias", props.categorias);
    const mapCategory = props.categorias.map((category: any) => {
      const catRes = {
        id: category.id,
        categoria: props.mostrarTodos ? category : category.categoria,
        nome: category.nome,
      };
      return catRes;
    });
    console.log("new cat", mapCategory);
    setCategorias(mapCategory);
  }, []);

  const [categorias, setCategorias] = useState<ICategoria[]>([]);
  const sliderRef = useRef<any>(null);

  // return "teste";

  return (
    <Flex
      w="full"
      flexDir="column"
      bgImage={props.linhaDupla ? "/bg-search-page.png" : ""}
      bgPos=" right -120px"
      bgRepeat="no-repeat"
    >
      <Flex
        maxW={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        py={[14, 14, 20]}
        flexDir="column"
        w="full"
      >
        <Flex>
          <Flex flexDir="column" w={["80%"]}>
            {props.subtitulo && (
              <Text
                color="#017DC5"
                fontSize="14px"
                textTransform="uppercase"
                mb={4}
              >
                {props.subtitulo}
              </Text>
            )}
            <Heading as="h2" fontSize="24px" color="#101010" pr={[0, 0, "40%"]}>
              {props.titulo}
            </Heading>
            <Divider
              bgColor="#ED1B2F"
              height={1}
              borderRadius={4}
              maxW="40px"
              mt={4}
              mb={8}
            />
          </Flex>

          <Flex justifyContent="flex-end">
            <HStack spacing={[0]}>
              <ChevronLeftIcon
                onClick={() => {
                  sliderRef.current.slickPrev();
                }}
                color="#007DC5"
                fontSize={["2.5em", "2.5em", "3em"]}
                cursor="pointer"
                opacity={0.5}
                _hover={{ opacity: 1 }}
              />
              <ChevronRightIcon
                onClick={() => sliderRef.current.slickNext()}
                color="#007DC5"
                fontSize={["2.5em", "2.5em", "3em"]}
                cursor="pointer"
                opacity={0.5}
                _hover={{ opacity: 1 }}
              />
            </HStack>
          </Flex>
        </Flex>

        <Slider ref={sliderRef} {...settings} slidesToShow={slidetoShow}>
          {categorias &&
            chunk(categorias, itemPerSlide).map((slice, index) => (
              <Flex key={index} px="1">
                <Grid
                  templateColumns={templateColumns}
                  gap={[4, 4, 16]}
                  rowGap={{ base: 14, sm: 14 }}
                  pb={8}
                >
                  {slice &&
                    slice.map((categoria) => (
                      <Box
                        key={categoria.categoria.id}
                        borderRadius="20px"
                        border="1px solid #dadada"
                        maxW="230px"
                        position="relative"
                      >
                        <NextLink
                          href={`/produtos/categoria/${categoria.categoria.slug}`}
                          passHref
                        >
                          <Link>
                            <Box
                              h={["200px", "200px", "300px"]}
                              borderRadius="20px"
                              bgImage={
                                categoria.categoria.imagem
                                  ? getMedia(categoria.categoria.imagem)
                                  : "/blog-default.png"
                              }
                              bgRepeat="no-repeat"
                              bgSize="cover"
                              bgPosition="center"
                            ></Box>
                            <Button
                              textTransform="uppercase"
                              fontWeight="normal"
                              position="absolute"
                              w="80%"
                              left="10%"
                              bottom="-18px"
                              zIndex="1"
                              color="white"
                              bgColor="#017DC5"
                              fontSize="14px"
                              lineHeight={1}
                              textAlign="center"
                              whiteSpace="normal"
                              _hover={{
                                bgColor: "#00377B",
                              }}
                            >
                              {categoria.nome}
                            </Button>
                          </Link>
                        </NextLink>
                      </Box>
                    ))}
                </Grid>
              </Flex>
            ))}
        </Slider>
      </Flex>
    </Flex>
  );
}
