import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form as Unform } from "@unform/web";
import Input from "./Input";
import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import styled from "styled-components";
import { useToast } from "@chakra-ui/react";
import FileInput from "./FileInput";
import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";
import axios from "axios";

interface ErrorProps {
  [key: string]: string;
}
interface FormData {
  name: string;
  email: string;
  password: string;
  curriculo: File;
}
export const Form = styled(Unform)`
  width: 100%;
`;

export default function FormRepresentante() {
  const formRef = useRef<FormHandles>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const toast = useToast();

  const recaptchaRef = React.createRef<any>();

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      // Remove all previous errors
      if (formRef.current != null) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        nome: Yup.string().required("Campo obrigatório."),
        estado: Yup.string().required("Campo obrigatório."),
        cidade: Yup.string().required("Campo obrigatório."),
        telefone: Yup.string().required("Campo obrigatório."),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const recaptchaValue = recaptchaRef.current?.getValue();
      if (!recaptchaValue) {
        toast({
          title: "Erro",
          description: "Por favor, confirme que você não é um robô.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Validation passed
      // SEND MAIL
      const formInfo = data;
      {
        let data = new FormData();
        data.append("data", JSON.stringify(formInfo));
        data.append("files.curriculo", formInfo.curriculo);

        const sending = await axios.post(
          `${
            process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
          }/form/oportunidade`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      toast({
        title: "Solicitação enviada.",
        description: "Em breve entraremos em contato.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (err) {
      const validationErrors: ErrorProps = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.path != undefined) {
            validationErrors[error.path] = error.message;
          }
        });
        if (formRef.current != null) {
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  };
  return (
    <Flex w={["full", "full", "full", "50%"]}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid templateColumns="repeat(1, 1fr)" gap="6">
          <GridItem>
            <Input
              name="nome"
              label="Nome completo"
              placeholder="Insira seu nome completo"
            />
          </GridItem>
          <GridItem>
            <Input
              name="estado"
              label="Estado"
              placeholder="Insira o nome do seu estado"
            />
          </GridItem>

          <GridItem>
            <Input
              name="cidade"
              label="Cidade"
              placeholder="Insira o nome da sua cidade"
            />
          </GridItem>
          <GridItem>
            <Input
              name="telefone"
              label="Telefone (com ddd)"
              placeholder="Insira seu telefone (com DDD)"
            />
          </GridItem>
          <GridItem>
            <FileInput
              name="curriculo"
              label="Currículo"
              placeholder="Selecione o seu currículo"
            />
          </GridItem>
          <GridItem>
            <ReCAPTCHA
              sitekey="6LfAbYUeAAAAANymlm8n_qR1FqN9KFEWtG1Bd0yN"
              ref={recaptchaRef}
            />
          </GridItem>
        </Grid>

        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmit}
          type="submit"
          bgColor="#007DC5"
          fontSize="14px"
          borderRadius="12px"
          px={14}
          _hover={{ bgColor: "#007DC5" }}
        >
          Enviar formulário
        </Button>
      </Form>
    </Flex>
  );
}
