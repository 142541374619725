import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  ModalProps,
  UseDisclosureProps,
  useToast,
} from "@chakra-ui/react";
import TitleDefault from "../../TitleDefault";
import Input from "@chakra-ui/react";
import FormDownloadModal from "./FormDownloadModal";
import { IMedia } from "../../../interfaces/Media";

interface DonwloadCategoryProps {
  id: number;
  titulo: string;
  slug: string;
}

interface DonwloadProps {
  id: number;
  titulo: string;
  link: string;
  solicitarCadastro: boolean;
  imagem: IMedia;
  download_categorias: DonwloadCategoryProps[];
  ativarLinkExterno: boolean;
  arquivo: {
    id: number;
    name: string;
    url: string;
  };
}
interface Props {
  isOpen: boolean;
  onClose?(): void;
  onOpen?(): void;
  download: DonwloadProps | undefined;
}

export default function DownloadModal({ isOpen, onClose, download }: Props) {
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={() => onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pt="8">
            <TitleDefault titulo="Download" subtitulo="Acesso" />
            <Text mb="8">
              Para ter acesso ao material selecionado, por favor preencha os
              campos abaixo!
            </Text>
            <FormDownloadModal download={download} />
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
