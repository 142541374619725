import React from "react";
import { Divider, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import RichText from "../../RichText";
import styled from "@emotion/styled";

interface Props {
  boxTituloMaisTexto: {
    id: number;
    titulo: string;
    texto: string;
  }[];
}

const GridStyle = styled(Grid)`
  ul {
    margin-left: 20px;
  }
`;

export default function BoxDestaqueVariasColunas(props: Props) {
  return (
    <Flex w="full" flexDir="column">
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        py={[4, 4, 4, 10]}
        flexDir="column"
      >
        <GridStyle
          templateColumns={["1fr", "1fr", "1fr", "repeat(3, 1fr)"]}
          gap="10"
        >
          {props.boxTituloMaisTexto &&
            props.boxTituloMaisTexto.map((box) => (
              <GridItem key={box.id} boxShadow="md" borderRadius="20px" p={8}>
                {box.titulo && (
                  <>
                    <Heading
                      as="h3"
                      fontWeight="normal"
                      fontSize="24px"
                      color="#101010"
                      pr="40%"
                    >
                      {box.titulo}
                    </Heading>
                    <Divider
                      bgColor="#ED1B2F"
                      height={1}
                      borderRadius={4}
                      maxW="40px"
                      mt={4}
                      mb={8}
                    />
                    <RichText text={box.texto} />
                  </>
                )}
              </GridItem>
            ))}
        </GridStyle>
      </Flex>
    </Flex>
  );
}
