import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import NextLink from "next/link";
import React from "react";
import IconEyes from "../../../assets/icons/icon-eyes.svg";
import { IMedia } from "../../../interfaces/Media";
import { getMedia } from "../../../lib/media";

const MotionBox = motion<BoxProps>(Box);
const MotionFlex = motion<FlexProps>(Flex);

interface files {
  file: DonwloadProps;
  onOpen(): void;
  setDownload: any;
}

interface DonwloadProps {
  id: number;
  titulo: string;
  link: string;
  solicitarCadastro: boolean;
  imagem: IMedia;
  ativarLinkExterno: boolean;
  arquivo: {
    id: number;
    name: string;
    url: string;
  };
}

const labelMotion = {
  rest: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
  hover: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

const fileItemEffect = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export default function DownloadItem({ file, onOpen, setDownload }: files) {
  let linkFile = "";
  if (file.ativarLinkExterno) {
    linkFile = file.link;
  } else {
    linkFile = file?.arquivo?.url || "";
  }

  const itemHandler = () => {
    if (file.solicitarCadastro) {
      setDownload(file);
      onOpen();
    } else {
      if (file?.ativarLinkExterno) {
        window.open(file?.link, "_blank");
      } else {
        window.open(file?.arquivo?.url, "_blank");
      }
    }
  };
  return (
    <MotionBox
      variants={fileItemEffect}
      initial="visible"
      onClick={itemHandler}
    >
      {/* <MotionBox variants={fileItemEffect}> */}
      {/* <NextLink href={linkFile} passHref> */}
      {/* <Link target="_blank"> */}
      <MotionBox
        initial="rest"
        whileHover="hover"
        animate="rest"
        cursor="pointer"
      >
        <Box
          border="1px solid #DADADA"
          borderRadius="20px"
          overflow="hidden"
          position="relative"
        >
          <Image src={getMedia(file.imagem!)} w="full" />
          <MotionFlex
            w="full"
            justifyContent="center"
            alignItems="center"
            color="white"
            bgColor="#00377B"
            h={14}
            position="absolute"
            zIndex="1"
            bottom="0"
            variants={labelMotion}
          >
            <Box mr={2}>
              <IconEyes />
            </Box>
            Download do material
          </MotionFlex>
        </Box>
        <Text
          fontSize="14px"
          fontWeight="bold"
          px="15%"
          mt={4}
          textAlign="center"
        >
          {file.titulo}
        </Text>
      </MotionBox>
      {/* </Link> */}
      {/* </NextLink> */}
    </MotionBox>
  );
}
