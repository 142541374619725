import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import {
  FormLabel,
  Text,
  Input as InputCK,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
// import ReactInputMask, { Props as MaskInputProps } from "react-input-mask";
interface Props extends ChakraInputProps {
  name: string;
  label?: string;
}
// type InputProps = Props & MaskInputProps;
export default function Input({ name, label, ...rest }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);
  return (
    <>
      {label && (
        <FormLabel
          htmlFor={fieldName}
          color="#00377B"
          textTransform="uppercase"
          fontWeight="bold"
          fontSize="14px"
        >
          {label}
        </FormLabel>
      )}
      <InputCK
        // as={ReactInputMask}
        id={fieldName}
        type="text"
        ref={inputRef}
        defaultValue={defaultValue}
        isInvalid={error ? true : false}
        {...rest}
      />
      {error && (
        <Text mt={2} color="#E53E3E">
          {error}
        </Text>
      )}
    </>
  );
}
