import React, { useEffect, useState } from "react";
import { Flex, Text, Heading, Divider, Image, Link } from "@chakra-ui/react";
import NextLink from "next/link";
import { IMedia } from "../../../interfaces/Media";
import { getMedia } from "../../../lib/media";
import { motion } from "framer-motion";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import RichText from "../../RichText";
import { useMediaQuery } from "react-responsive";

interface Props {
  id: number;
  subtitulo: string;
  titulo: string;
  tituloTexto: string;
  descricao: string;
  link: string;
  reverterColuna: boolean;
  imagem: IMedia;
}

const MotionLink = motion(Link);

export default function BoxTextoImagem(props: Props) {
  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });
  const [flexDir, setFlexDir] = useState(
    props.reverterColuna ? "row-reverse" : "row"
  );
  const [paddingRight, setPaddingRight] = useState(
    props.reverterColuna ? 0 : 12
  );
  const [paddingLeft, setPaddingLeft] = useState(props.reverterColuna ? 12 : 0);

  useEffect(() => {
    if (isSmallerThan768) {
      setFlexDir("column");
      setPaddingLeft(0);
      setPaddingRight(0);
    } else {
      setFlexDir(props.reverterColuna ? "row-reverse" : "row");
      setPaddingRight(props.reverterColuna ? 0 : 12);
      setPaddingLeft(props.reverterColuna ? 12 : 0);
    }
  }, [isSmallerThan768]);

  return (
    <Flex w="full" flexDir="column">
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        py={10}
        flexDir="column"
      >
        <Flex flexDir="column" flex="1">
          {props.subtitulo && (
            <Text
              color="#017DC5"
              fontSize="14px"
              textTransform="uppercase"
              mb={4}
            >
              {props.subtitulo}
            </Text>
          )}
          {props.titulo && (
            <>
              <Heading as="h2" fontSize="24px" color="#101010" pr="40%">
                {props.titulo}
              </Heading>
              <Divider
                bgColor="#ED1B2F"
                height={1}
                borderRadius={4}
                maxW="40px"
                mt={4}
                mb={8}
              />
            </>
          )}
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="space-between"
          position="relative"
          flexDir={flexDir as React.CSSProperties["flexDirection"]}
        >
          {props.imagem && (
            <Image
              src={getMedia(props.imagem)}
              w="100%"
              maxW={["100%", "100%", "45%"]}
              position="relative"
              zIndex="1"
              mb={{ base: 10, sm: 10 }}
            />
          )}
          <Flex
            flexDir="column"
            pr={paddingRight}
            pl={paddingLeft}
            maxW={["100%", "100%", "50%"]}
            position="relative"
            zIndex="1"
          >
            {props.tituloTexto && (
              <Heading as="h3" fontSize="26px" mb={6}>
                {props.tituloTexto}
              </Heading>
            )}
            {props.descricao && (
              <Flex fontSize="18px" flexDir="column">
                <RichText text={props.descricao} />
              </Flex>
            )}
            {props.link && (
              <NextLink href={props.link} passHref>
                <MotionLink
                  color="#00377B"
                  fontWeight="semibold"
                  fontSize="14px"
                  display="block"
                  textAlign="left"
                  py={2}
                  mt={8}
                  _focus={{ outline: "none" }}
                  whileHover={{ x: 5 }}
                >
                  Ver conteúdo completo <ArrowForwardIcon />
                </MotionLink>
              </NextLink>
            )}
          </Flex>
          {!isSmallerThan768 && (
            <Flex
              w="80%"
              h="80%"
              top="10%"
              left={props.reverterColuna ? "0px" : "20%"}
              bgColor="white"
              position="absolute"
              boxShadow="md"
              borderRadius="20px"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
