import React from "react";
import { Flex } from "@chakra-ui/react";
import RichText from "../../RichText";
import styled from "styled-components";

interface Props {
  id: number;
  texto: string;
}

const FlexStyle = styled(Flex)`
  ul {
    margin-left: 30px;
    li:before {
      content: "";
      display: block;
      width: 15px;
      height: 3px;
      background-color: #ed1b2f;
      margin: 8px 0 20px;
      position: absolute;
      left: -28px;
      top: 2px;
    }

    li {
      list-style: none;
      position: relative;
    }
  }
`;

export default function UmaColunaTexto(props: Props) {
  return (
    <Flex w="full" flexDir="column">
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        py={[4, 4, 4, 10]}
      >
        <FlexStyle flexDir="column" w="full">
          {props.texto && <RichText text={props.texto} />}
        </FlexStyle>
      </Flex>
    </Flex>
  );
}
