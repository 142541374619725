import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import TitleDefault from "../../TitleDefault";
import { IMedia } from "../../../interfaces/Media";
import { getMedia } from "../../../lib/media";
import parse from "html-react-parser";
import RichText from "../../RichText";

interface Props {
  id: number;
  images: IMedia[];
}

export default function Imagem(props: Props) {
  return (
    <Flex w="full" flexDir="column">
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        py={10}
        flexDir="column"
      >
        <Flex w="full">
          {props.images &&
            props.images.map((image) => (
              <Image
                key={image.id}
                src={getMedia(image)}
                w="100%"
                position="relative"
                zIndex="1"
              />
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
