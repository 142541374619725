import React, {
  useRef,
  useEffect,
  useCallback,
  ChangeEvent,
  useState,
} from "react";
import { useField } from "@unform/core";
import {
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
interface Props {
  name: string;
  label?: string;
}
type InputProps = JSX.IntrinsicElements["input"] & Props;
export default function FileInput({
  name,
  label,
  placeholder,
  ...rest
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [fileName, setFileName] = useState<string>("");

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file?.name);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files[0]",
      clearValue(ref: HTMLInputElement) {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);
  return (
    <>
      {label && (
        <FormLabel
          htmlFor={fieldName}
          color="#00377B"
          textTransform="uppercase"
          fontWeight="bold"
          fontSize="14px"
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Input
          bg="white"
          defaultValue={defaultValue}
          isInvalid={error ? true : false}
          placeholder={placeholder || "Selecionar arquivo ..."}
          value={fileName}
          onChange={() => {}}
          onClick={() => {
            inputRef.current?.click();
          }}
          mb={4}
        />
        <InputRightElement children={<SearchIcon color="#E53E3E" />} />
      </InputGroup>
      {error && (
        <Text mt={2} color="#E53E3E">
          {error}
        </Text>
      )}
      <input
        type="file"
        id={fieldName}
        ref={inputRef}
        onChange={handlePreview}
        style={{ display: "none" }}
        {...rest}
      />
    </>
  );
}
