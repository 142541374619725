import React from "react";
import { Flex, Text, Heading, Divider } from "@chakra-ui/react";
import FormGerarBoletos from "./FormGerarBoleto";

interface Props {
  subtitulo: string;
  titulo: string;
  texto: string;
}

export default function GerarBoletos(props: Props) {
  return (
    <Flex w="full" flexDir="column" mt={["0", "0", "0", "-110px"]}>
      <Flex w={["80%", "80%", "90%", "90%", "1170px"]} mx="auto">
        <Flex flexDir="column" w="full">
          {props.subtitulo && (
            <Text
              color="#017DC5"
              fontSize="14px"
              textTransform="uppercase"
              mb={4}
            >
              {props.subtitulo}
            </Text>
          )}
          {props.titulo && (
            <>
              <Heading as="h2" fontSize="24px" color="#101010">
                {props.titulo}
              </Heading>
              <Divider
                bgColor="#ED1B2F"
                height={1}
                borderRadius={4}
                maxW="40px"
                mt={4}
                mb={8}
              />
            </>
          )}
          {props.texto && <Text mb={4}>{props.texto}</Text>}

          <FormGerarBoletos />
        </Flex>
      </Flex>
    </Flex>
  );
}
