import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Flex, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { IMedia } from "../../../interfaces/Media";
import Slider from "react-slick";
import { getMedia } from "../../../lib/media";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

interface Props {
  bannerSlide: BanenrSlideItem[];
}

interface BanenrSlideItem {
  id: number;
  primeiraLinha: string;
  segundaLinha: string;
  link: string;
  linkExterno: boolean;
  image: IMedia;
  imageCelular: IMedia;
}
const SliderWrapper = styled.div`
  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li button:before {
    width: 30px;
    content: "" !important;
    height: 6px;
    background: #fff;
    border-radius: 5px;
    display: block;
    box-shadow: 1px 1px 4px #0000008c;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .slick-dots li button {
    width: 30px;
    display: block;
    height: 6px;
  }

  .slick-dots li {
    width: auto;
  }
  .slick-prev {
    left: 25px;
    z-index: 5;
  }
  .slick-next {
    right: 25px;
    z-index: 5;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
  }
`;

export default function BannerPrincipal({ bannerSlide }: Props) {
  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
  };

  const isSmallerThan768 = useMediaQuery({ query: "(max-width: 768px)" });
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (isSmallerThan768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isSmallerThan768]);

  const getBannerImage = (item: BanenrSlideItem) => {
    if (isMobile) {
      if (item.imageCelular) {
        return getMedia(item.imageCelular);
      } else {
        return getMedia(item.image);
      }
    } else {
      return getMedia(item.image);
    }
  };

  return (
    <Flex w="full" flexDir="column">
      <SliderWrapper>
        <Slider {...settings}>
          {bannerSlide &&
            bannerSlide.map((item) => (
              <Box key={item.id}>
                {item.link !== "" ? (
                  <Link
                    href={item.link}
                    target={item.linkExterno ? "_blank" : "_self"}
                  >
                    <Flex
                      h={["450px", "450px", "580px"]}
                      w="full"
                      bgImage={getBannerImage(item)}
                      bgRepeat="no-repeat"
                      bgSize="cover"
                      bgPosition="center top"
                    >
                      <Flex
                        maxW={["80%", "80%", "90%", "90%", "1170px"]}
                        mx="auto"
                        h="full"
                        alignItems="flex-end"
                      >
                        <Box mb={[16, 16, 20]}>
                          {item.primeiraLinha && (
                            <Text
                              fontSize={["1.6em", "1.6em", "50px"]}
                              lineHeight="1.2em"
                              color="white"
                              textShadow="0 0 10px #000"
                            >
                              {item.primeiraLinha}
                            </Text>
                          )}
                          {item.segundaLinha && (
                            <Text
                              fontSize={["1.6em", "1.6em", "50px"]}
                              lineHeight="1.2em"
                              color="white"
                              fontWeight="bold"
                              textShadow="0 0 10px #000"
                            >
                              {item.segundaLinha}
                            </Text>
                          )}
                        </Box>
                      </Flex>
                    </Flex>
                  </Link>
                ) : (
                  <Flex
                    key={item.id}
                    h={["450px", "450px", "580px"]}
                    w="full"
                    bgImage={getMedia(item.image)}
                    bgRepeat="no-repeat"
                    bgSize="cover"
                    bgPosition="center top"
                  >
                    <Flex
                      maxW={["80%", "80%", "90%", "90%", "1170px"]}
                      mx="auto"
                      h="full"
                      alignItems="flex-end"
                    >
                      <Box mb={[16, 16, 20]}>
                        {item.primeiraLinha && (
                          <Text
                            fontSize={["1.6em", "1.6em", "50px"]}
                            lineHeight="1.2em"
                            color="white"
                            textShadow="0 0 10px #000"
                          >
                            {item.primeiraLinha}
                          </Text>
                        )}
                        {item.segundaLinha && (
                          <Text
                            fontSize={["1.6em", "1.6em", "50px"]}
                            lineHeight="1.2em"
                            color="white"
                            fontWeight="bold"
                            textShadow="0 0 10px #000"
                          >
                            {item.segundaLinha}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                )}
              </Box>
            ))}
        </Slider>
      </SliderWrapper>
    </Flex>
  );
}
