import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  GridProps,
  HStack,
  StackProps,
  useDisclosure,
} from "@chakra-ui/react";
import { find } from "lodash";
import TitleDefault from "../../TitleDefault";
import { IMedia } from "../../../interfaces/Media";
import DownloadItem from "./DownloadItem";
import { motion } from "framer-motion";
import DownloadModal from "./DownloadModal";

interface DonwloadCategoryProps {
  id: number;
  titulo: string;
  slug: string;
}
interface DonwloadProps {
  id: number;
  titulo: string;
  link: string;
  solicitarCadastro: boolean;
  imagem: IMedia;
  download_categorias: DonwloadCategoryProps[];
  ativarLinkExterno: boolean;
  arquivo: {
    id: number;
    name: string;
    url: string;
  };
}

interface Prop {
  id: number;
  titulo: string;
  subtitulo: string;
  downloadsCategories: DonwloadCategoryProps[];
  downloads: DonwloadProps[];
}

const MotionGrid = motion<GridProps>(Grid);
const MotionHStack = motion<StackProps>(HStack);

export default function DownloadArea(props: Prop) {
  const [activeCategory, setActiveCategory] = useState(
    props.downloadsCategories[0].id
  );
  const handleActiveCategory = (id: number) => {
    setActiveCategory(id);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const stackRef = useRef<HTMLDivElement | null>(null);
  const [constraintsRef, setConstraintsRef] = useState<Object>({});
  useEffect(() => {
    // get element width
    const scrollWidth = stackRef.current ? stackRef.current.scrollWidth : 0;
    const offsetWidth = stackRef.current ? stackRef.current.offsetWidth : 0;
    const constraints = {
      left: -(scrollWidth - offsetWidth + 50),
      right: 0,
    };
    setConstraintsRef(constraints);
  }, []);

  const [activeDownload, setActiveDownload] = useState<DonwloadProps>();

  return (
    <Flex id="downloads" w="full" flexDir="column" mt="60px">
      <Flex w={["80%", "80%", "90%", "90%", "1170px"]} mx="auto">
        <TitleDefault titulo="Downloads" />
      </Flex>
      <Flex w="full" bgColor="#EFEFF1">
        <Flex
          w={["100%", "100%", "100%", "100%", "1170px"]}
          pl={[10, 10, 10, 10, 0]}
          mx="auto"
          flexDir="column"
        >
          <MotionHStack
            spacing={3}
            drag="x"
            dragConstraints={constraintsRef}
            ref={stackRef}
          >
            {props.downloadsCategories &&
              props.downloadsCategories.map((cat) => (
                <Button
                  minW="auto"
                  display="block"
                  key={cat.id}
                  borderRadius="12px"
                  onClick={() => handleActiveCategory(cat.id)}
                  px={[4, 4, 4, 10]}
                  color={cat.id === activeCategory ? "white" : "#007DC5"}
                  bgColor={
                    cat.id === activeCategory ? "#00377B" : "transparent"
                  }
                  _hover={{ bgColor: "#00377B", color: "white" }}
                >
                  {cat.titulo}
                </Button>
              ))}
          </MotionHStack>
        </Flex>
      </Flex>
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        mx="auto"
        mt={14}
        flexDir="column"
      >
        {props.downloadsCategories &&
          props.downloadsCategories.map((cat) => (
            <MotionGrid
              key={cat.id}
              templateColumns={[
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(4, 1fr)",
              ]}
              gap="12"
              overflow="hidden"
              initial={{ opacity: 0 }}
              h={cat.id === activeCategory ? "auto" : 0}
              animate={{
                opacity: cat.id === activeCategory ? 1 : 0,
              }}
            >
              {props.downloads &&
                props.downloads
                  .filter((file) =>
                    find(file.download_categorias, { id: cat.id })
                  )
                  .map((file) => (
                    <GridItem w="full" key={file.id} position="relative">
                      <DownloadItem
                        file={file}
                        onOpen={onOpen}
                        setDownload={setActiveDownload}
                      />
                    </GridItem>
                  ))}
            </MotionGrid>
          ))}
      </Flex>
      <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        download={activeDownload}
      />
    </Flex>
  );
}
