import delve from "dlv";

interface HeroProps {
  header: object;
}

const Hero = ({ header }: HeroProps) => {
  const title = delve(header, "title");

  return <h1>Hero component</h1>;
};

export default Hero;
