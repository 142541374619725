import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form as Unform } from "@unform/web";
import Input from "./Input";
import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import styled from "styled-components";
import { useToast } from "@chakra-ui/react";

interface ErrorProps {
  [key: string]: string;
}
interface FormData {
  name: string;
  email: string;
  password: string;
}
export const Form = styled(Unform)`
  width: 100%;
`;

export default function FormGerarBoletos() {
  const formRef = useRef<FormHandles>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const toast = useToast();

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      // Remove all previous errors
      if (formRef.current != null) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        cnpjCpf: Yup.string()
          .max(14, "Máximo de 14 caracteres.")
          .required("Campo obrigatório."),
        notaFiscal: Yup.string()
          .max(9, "Máximo de 9 caracteres.")
          .required("Campo obrigatório."),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      console.log(data);
      toast({
        title: "Solicitação enviada.",
        description:
          "Em breve você recebera em seu e-mail a segunda via do boleto.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (err) {
      const validationErrors: ErrorProps = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.path != undefined) {
            validationErrors[error.path] = error.message;
          }
        });
        if (formRef.current != null) {
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  };
  return (
    <Flex w="full">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
          gap="10"
        >
          <GridItem>
            <Input
              name="cnpjCpf"
              label="CNPJ/CPF"
              placeholder="Somente números"
            />
          </GridItem>
          <GridItem>
            <Input
              name="notaFiscal"
              label="Nº Nota Fiscal"
              placeholder="Até 9 dígitos"
            />
          </GridItem>
        </Grid>

        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmit}
          type="submit"
          bgColor="#007DC5"
          fontSize="14px"
          borderRadius="12px"
          px={14}
          _hover={{ bgColor: "#007DC5" }}
        >
          Enviar
        </Button>
      </Form>
    </Flex>
  );
}
